<template>
    <DxChart
        ref="chart_print"
        id="chart_print"
        :data-source="Model.dataSource"
        :argumentAxis="{
            discreteAxisDivisionMode: 'crossLabels',
            label: {
                alignment: 'center',
                font: { size: 15, color: '#000', opacity: 0.87 },
                overlappingBehavior: Model.TextX == 'Ngày' ? 'rotate' : 'hide',
                rotationAngle: -45,
            },
            title: {
                text: Model.TextX,
                font: { size: 14, color: '#000', weight: 500 },
            },
        }"
        :valueAxis="{
            showZero: true,
            axisDivisionFactor: 150,
            allowDecimals: false,
            label: { font: { size: 16, color: '#000', opacity: 0.87 } },
            title: {
                text: Model.TextY,
                font: { size: 14, color: '#000', weight: 500 },
            },
        }"
        :size="{ width: Model.widthContent, height: Model.heightContent }"
    >
        <DxCommonSeriesSettings argument-field="Title" />
        <DxSeries
            v-for="item in Model.columnName"
            :type="Model.typeBieuDo"
            :key="item.Id"
            :value-field="item.Id"
            :name="item.Name == '' ? 'Không có dữ liệu' : item.Name"
            :label="{
                backgroundColor: !item.Show ? 'transparent' : '',
                visible: Model.showLabel,
                rotationAngle:
                    Model.typeBieuDo == 'stackedbar' && !item.Show ? 90 : 0,
                position: !!item.Show ? 'outside' : 'inside',
                customizeText: customizeText,
            }"
            :stack="item.Stack"
            :barWidth="Model.dataSource.length > 20 ? 20 : 32"
        />

        <DxSeries
            v-if="!!Model.BarWithLine && !!Model.showBien"
            v-for="(item, i) in Model.MaxMinColumnName"
            :key="item.Id + i"
            type="spline"
            :value-field="item.Id"
            :name="item.Name == '' ? 'Không có dữ liệu' : item.Name"
            :width="1"
            :hoverStyle="{ width: 1.5 }"
            :point="{ visible: false }"
            :label="{
                visible: Model.showLabel,
                position: !!item.Show ? 'outside' : 'inside',
                customizeText: customizeText,
            }"
        />
        <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="right"
            :font="{ size: 16 }"
        />
    </DxChart>
</template>

<script>
import DxChart, {
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
} from "devextreme-vue/chart";
export default {
    components: {
        DxChart,
        DxSeries,
        DxCommonSeriesSettings,
        DxLegend,
    },
    props: {
        Model: {
            type: Object,
            default: {
                typeBieuDo: "line",
                TextX: null,
                TextY: null,
                TuNgay: null,
                DenNgay: null,
                showLabel: false,
                showBien: false,
                dataSource: [],
                columnName: [],
                MaxMinColumnName: [],
                widthContent: 500,
                heightContent: 500,
                BarWithLine: false,
            },
        },
        Export: { type: Number },
    },
    data() {
        return {};
    },
    watch: {
        Model: {
            immediate: true,
            handler: function (val) {},
            deep: true,
        },
        Export: {
            handler: function (val) {
                this.exportChart();
            },
            deep: true,
        },
    },
    methods: {
        customizeText(e) {
            if (
                this.Model.typeBieuDo == "line" ||
                this.Model.typeBieuDo == "spline"
            ) {
                return e.value;
            } else if (this.Model.typeBieuDo == "bar") {
                return e.value;
            } else if (this.Model.typeBieuDo == "stackedbar") {
                let show = false;
                this.Model.columnName.forEach((x) => {
                    if (e.seriesName == x.Name && x.Show == true) {
                        show = true;
                    }
                });
                if (!!show) {
                    return e.total;
                } else {
                    return e.value;
                }
            }
        },
        exportChart() {
            this.$refs.chart_print.instance.exportTo("BieuDo", "png");
        },
    },
    // created() {
    //     this.Model.showLabel = false;
    //     this.Model.typeBieuDo = localStorage.getItem("typeBieuDo");
    //     this.Model.TextX = localStorage.getItem("TextX");
    //     this.Model.TextY = localStorage.getItem("TextY");
    //     this.Model.TuNgay = localStorage.getItem("TuNgay");
    //     this.Model.DenNgay = localStorage.getItem("DenNgay");
    //     this.Model.widthContent = localStorage.getItem("widthContent");
    //     this.Model.heightContent = localStorage.getItem("heightContent");
    //     if (localStorage.getItem("showLabel") == "true") {
    //         this.Model.showLabel = true;
    //     }
    //     this.Model.dataSource = JSON.parse(localStorage.getItem("dataSource"));
    //     this.Model.columnName = JSON.parse(localStorage.getItem("columnName"));
    //     console.log("created -> this.Model", this.Model);
    //     setTimeout(() => {
    //         window.print();
    //     }, 2000);
    // }
    // beforeDestroy() {
    //     localStorage.removeItem("typeBieuDo");
    //     localStorage.removeItem("TextX");
    //     localStorage.removeItem("TextY");
    //     localStorage.removeItem("TuNgay");
    //     localStorage.removeItem("DenNgay");
    //     localStorage.removeItem("widthContent");
    //     localStorage.removeItem("heightContent");
    //     localStorage.removeItem("dataSource");
    //     localStorage.removeItem("columnName");
    // }
};
</script>