export default {
    namespaced: true,
    state: () => ({
        ThongTinSo: "",
        ThongTinDonViTheoSo: "",
        ThongTinBenXeTheoSo: "",
        DanhSachSo: {},
        DanhSachBenTheoSo: [],
        DanhSachDonViTheoSo: [],
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        async LayDanhSachBaoCaoHoatDongXe(store, query, key) {
            store;
            this.$startLoading;
            var load = this.$Helper.Common.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let resp = await this.$Core.Api.BaoCaoBenXe(
                            this.$i18n.global.t("url.BaoCaoHoatDongXeList")
                        ).Post({
                            loadOptions,
                            args: query,
                        });
                        if (resp.Data.State === "Success") {
                            console.log(
                                "🚀 ~ file: BaoCaoThongKe.js ~ line 68 ~ resp",
                                resp
                            );
                            let result =
                                resp.Data.Data && resp.Data.Data.data
                                    ? resp.Data.Data
                                    : [];
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        async Get_DanhSachChiTietHoatDongCuaXe(store, query, key) {
            store;
            var load = this.$Helper.Common.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let resp = await this.$Core.Api.BaoCaoBenXe(
                            this.$i18n.global.t("url.BaoCaoHoatDongTheoXeList")
                        ).Post({
                            loadOptions,
                            args: query,
                        });

                        if (resp.Data.State === "Success") {
                            console.log("success");
                            let result =
                                resp.Data.Data && resp.Data.Data.data
                                    ? resp.Data.Data
                                    : [];
                            return result;
                        } else {
                            console.log("error");
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        async Get_DanhSachSo(store) {
            try {
                this.startLoading();
                let rs = await this.$Core.Api.BaoCaoBenXe(
                    this.$i18n.global.t("url.DungChungDanhSachSo")
                ).Get();
                if (rs.Data.State == "Success") {
                    store.commit("Set", {
                        key: "DanhSachSo",
                        data: rs.Data,
                    });
                    return rs.Data || {};
                } else {
                    this.stopLoading();
                    return {};
                }
            } catch (error) {
                this.stopLoading();
                console.log(
                    "🚀 ~ file: BaoCaoThongKe.js ~ line 65 ~ Get_DanhSachSo ~ error",
                    error
                );
            } finally {
                this.stopLoading();
            }
        },

        async Get_TongHopCuaXeTheoThang(store, query, key) {
            store;
            var load = this.$Helper.Common.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        let resp = await this.$Core.Api.BaoCaoBenXe(
                            this.$i18n.global.t(
                                "url.HoatDongBenXeTheoThangList"
                            )
                        ).Post({
                            loadOptions,
                            args: query,
                        });
                        if (resp.Data.State === "Success") {
                            let result =
                                resp.Data.Data && resp.Data.Data.data
                                    ? resp.Data.Data
                                    : [];
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        async Get_BaoCaoHoatDongCuaBenXe(store, query, key) {
            store;
            var load = this.$Helper.Common.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let resp = await this.$Core.Api.BaoCaoBenXe(
                            this.$i18n.global.t("url.HoatDongBenXeList")
                        ).Post({
                            loadOptions,
                            args: query,
                        });
                        if (resp.Data.State === "Success") {
                            let result =
                                resp.Data.Data && resp.Data.Data.data
                                    ? resp.Data.Data
                                    : [];
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        async Get_BaoCaoNhatTrinh(store, { url, query, key }) {
            store;
            var load = this.$Helper.Common.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let resp = await this.$Core.Api.BaoCaoBenXe(url).Post({
                            loadOptions,
                            args: query,
                        });
                        if (resp.Data.State === "Success") {
                            let result =
                                resp.Data.Data && resp.Data.Data.data
                                    ? resp.Data.Data
                                    : [];
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },

        async Get_BaoCaoTrangThaiTruyenTaiDuLieuBenXe(store, query, key) {
            store;
            var load = this.$Helper.Common.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let resp = await this.$Core.Api.BaoCaoBenXe(
                            this.$i18n.global.t("url.TrangThaiTruyenTaiMoi")
                        ).Post({
                            loadOptions,
                            args: query,
                        });
                        if (resp.Data.State === "Success") {
                            let result =
                                resp.Data.Data && resp.Data.Data.data
                                    ? resp.Data.Data
                                    : [];
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        async Get_BaoCaoViPhamTruyenTaiDuLieu(store, query, key) {
            store;
            var load = this.$Helper.Common.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let resp = await this.$Core.Api.BaoCaoBenXe(
                            this.$i18n.global.t("url.ViPhamTruyenTai_DanhSach")
                        ).Post({
                            loadOptions,
                            args: query,
                        });
                        if (resp.Data.State === "Success") {
                            let result =
                                resp.Data.Data && resp.Data.Data.data
                                    ? resp.Data.Data
                                    : [];
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
// function unset(store, key) {
//     store.commit("Unset", {
//         key: key
//     });
// }
