import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/home";
import menuApplication from "./layouts/menu-application";
import none from "./layouts/none";
import PrintChart from "./views/Print/Print-Chart.vue"
function loadView(view) {
    return () => import(`./views/${view}.vue`);
}
function loadViewIndex(view) {
    return () => import(`./views/${view}/index.vue`);
}

export default function ($Core, $i18n, $Helper) {
    const router = new createRouter({
        routes: [
            {
                path: "/",
                name: "home",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                redirect: { name: "Trang-Chu" },
                component: Home,
            },
            {
                path: "/Dang-Nhap",
                name: "DangNhap",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                redirect: { name: "Dang-Nhap" },
                component: loadView("Dang-Nhap"),
            },
            {
                path: "/Trang-Chu",
                name: "Trang-Chu",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                component: loadViewIndex("Trang-Chu"),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe",
                name: "Bao-Cao-Hoat-Dong-Cua-Xe",
                meta: {
                    requiresAuth: "DanhSachBaoCaoHoatDongCuaXe",
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe"
                ),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/Chi-Tiet",
                name: "Chi-Tiet-Bao-Cao-Hoat-Dong-Cua-Xe",
                meta: {
                    requiresAuth: "ChiTietBaoCaoHoatDongCuaXe",
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/Chi-Tiet"
                ),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/Danh-Sach-Chi-Tiet",
                name: "Danh-Sach-Chi-Tiet-Bao-Cao-Hoat-Dong-Cua-Xe",
                meta: {
                    requiresAuth: "DanhSachChiTietBaoCaoHoatDongCuaXe",
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe/Danh-Sach-Chi-Tiet"
                ),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Tong-Hop-Cua-Xe-Theo-Thang",
                name: "Tong-Hop-Cua-Xe-Theo-Thang",
                meta: {
                    requiresAuth: "DanhSachBaoCaoHoatDongCuaXeTheoThang",
                    layout: menuApplication,
                },
                component: loadView(
                    "Bao-Cao-Thong-Ke/Tong-Hop-Cua-Xe-Theo-Thang/index"
                ),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe",
                name: "Bao-Cao-Hoat-Dong-Cua-Ben-Xe",
                meta: {
                    requiresAuth: "DanhSachBaoCaoHoatDongCuaBenXe",
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe"
                ),
            },

            {
                path: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe/Bieu-Do",
                name: "Bieu-Do-Hoat-Dong-Cua-Ben-Xe",
                meta: {
                    requiresAuth: "BieuDoBaoCaoHoatDongCuaBenXe",
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe/Bieu-Do"
                ),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai",
                name: "Nhat-Ky-Truyen-Tai",
                meta: {
                    requiresAuth: "DanhSachBaoCaoNhatKyTruyenTai",
                    layout: menuApplication,
                },
                component: loadView(
                    "Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai/index"
                ),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai/Bieu-Do",
                name: "Bieu-Do-Nhat-Ky-Truyen-Tai",
                meta: {
                    requiresAuth: "BieuDoBaoCaoNhatKyTruyenTai",
                    layout: menuApplication,
                },
                component: loadView(
                    "Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai/Bieu-Do/index"
                ),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Trang-Thai-Truyen-Tai-Du-Lieu-Cua-Ben-Xe",
                name: "Trang-Thai-Truyen-Tai-Du-Lieu-Cua-Ben-Xe",
                meta: {
                    requiresAuth:
                        "DanhSachBaoCaoTrangThaiTruyenTaiDuLieuCuaBenXe",
                    layout: menuApplication,
                },
                component: loadView(
                    "Bao-Cao-Thong-Ke/Trang-Thai-Truyen-Tai-Du-Lieu-Cua-Ben-Xe/index"
                ),
            },
            {
                path: "/Bao-Cao-Thong-Ke/Bao-Cao-Vi-Pham-Truyen-Tai-Du-Lieu",
                name: "Bao-Cao-Vi-Pham-Truyen-Tai-Du-Lieu",
                meta: {
                    requiresAuth: "DanhSachBaoCaoLoiDuLieuTruyenTai",
                    layout: menuApplication,
                },
                component: loadView(
                    "Bao-Cao-Thong-Ke/Bao-Cao-Vi-Pham-Truyen-Tai-Du-Lieu/index"
                ),
            },
            {
                path: "/Print/Print-Chart",
                name: "print-chart",
                meta: {
                    requiresAuth: true,
                    layout: none,
                },
                component: PrintChart,
            },

            {
                path: "/Error",
                name: "Error",
                meta: {
                    requiresAuth: true,
                    layout: none,
                    title: "Lỗi!",
                },
                component: loadView("Error"),
            },

            {
                path: "/",
                redirect: "/home",
            },
            {
                path: "/recovery",
                redirect: "/home",
            },
            {
                path: "/:pathMatch(.*)*",
                redirect: "/home",
            },
        ],
        history: createWebHistory(),
    });

    router.beforeEach((to, from, next) => {
        if (to.name === "login-form" && $Core.Auth.IsAuth) {
            next({ name: "home" });
        }

        if (!$Core.Auth.IsAuth) {
            $Core.Auth.Login();
            // next({
            //     name: "login-form",
            //     query: { redirect: to.fullPath }
            // });
        } else {
            if (to.meta.requiresAuth == true || to.meta.requiresAuth == false) {
                next();
            } else {
                if (
                    $Helper.Common.CheckRole(
                        $i18n.global.t("Quyen.BaoCaoBenXe"),
                        $i18n.global.t("Quyen." + to.meta.requiresAuth)
                    )
                ) {
                    next();
                } else {
                    next({
                        name: "Error",
                        query: {
                            statusCode: 403,
                        },
                    });
                }
            }
        }

        // next({
        //     name: "Error",
        //     query: {
        //         statusCode: 403,
        //     },
        // });
    });
    return router;
}
