<template>
    <div>
        <div class="row title justify-center">Biểu đồ</div>
        <div class="row justify-center">
            <ChartVue :Model="Model" />
        </div>
    </div>
</template>

<script>
import ChartVue from "../../components/_Common/Components/Chart.vue";

export default {
    components: { ChartVue },
    data() {
        return {
            Model: {
                typeBieuDo: "line",
                TextX: null,
                TextY: null,
                TuNgay: null,
                DenNgay: null,
                showLabel: false,
                showBien: false,
                dataSource: [],
                columnName: [],
                MaxMinColumnName: [],
                widthContent: 500,
                heightContent: 500,
                BarWithLine: false
            }
        };
    },
    methods: {
        // customizeText(e) {
        //     if (this.Model.typeBieuDo == "line") {
        //         return e.value;
        //     } else if (this.Model.typeBieuDo == "bar") {
        //         return e.value;
        //     } else if (this.Model.typeBieuDo == "stackedbar") {
        //         let show = false;
        //         this.Model.columnName.forEach(x => {
        //             if (e.seriesName == x.Name && x.Show == true) {
        //                 show = true;
        //             }
        //         });
        //         if (!!show) {
        //             return e.total;
        //         } else {
        //             return e.value;
        //         }
        //     }
        // }
    },
    created() {
        this.Model.showLabel = false;
        this.Model.BarWithLine = false;
        this.Model.showBien = false;
        this.Model.typeBieuDo = localStorage.getItem("typeBieuDo");
        this.Model.TextX = localStorage.getItem("TextX");
        this.Model.TextY = localStorage.getItem("TextY");
        this.Model.TuNgay = localStorage.getItem("TuNgay");
        this.Model.DenNgay = localStorage.getItem("DenNgay");
        this.Model.widthContent = localStorage.getItem("widthContent");
        this.Model.heightContent = localStorage.getItem("heightContent");
        if (localStorage.getItem("showLabel") == "true") {
            this.Model.showLabel = true;
        }
        if (localStorage.getItem("BarWithLine") == "true") {
            this.Model.BarWithLine = true;
        }
        if (localStorage.getItem("showBien") == "true") {
            this.Model.showBien = true;
        }
        this.Model.dataSource = JSON.parse(localStorage.getItem("dataSource"));
        this.Model.MaxMinColumnName = JSON.parse(
            localStorage.getItem("MaxMinColumnName")
        );
        this.Model.columnName = JSON.parse(localStorage.getItem("columnName"));
        console.log("created -> this.Model", this.Model);
        setTimeout(() => {
            window.print();
        }, 2000);
    }
    // beforeDestroy() {
    //     localStorage.removeItem("typeBieuDo");
    //     localStorage.removeItem("TextX");
    //     localStorage.removeItem("TextY");
    //     localStorage.removeItem("TuNgay");
    //     localStorage.removeItem("DenNgay");
    //     localStorage.removeItem("widthContent");
    //     localStorage.removeItem("heightContent");
    //     localStorage.removeItem("dataSource");
    //     localStorage.removeItem("columnName");
    // }
};
</script>