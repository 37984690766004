
<template>
    <Application
        :mynav="menuNav"
        @DangXuat="DangXuat"
        PhienBan="0.0.211005.1"
        :propModelToast="modelToast"
        linkLogo="https://drvn.gov.vn/wp-content/uploads/2022/09/logo-1.png"
    >
        <template v-slot:default>
            <slot />
        </template>
    </Application>
</template>


<script>
import Application from "@sonphat/common-v1/components/application.vue";
const FE_BaoCaoLenh = SP["FE_BaoCaoLenh"];
const FE_QLTT = SP["FE_QLTT"];

export default {
    components: { Application },
    data() {
        return {
            menuNav: [
                {
                    id: "Home",
                    text: "Trang chủ",
                    icon: "home",
                    to: "/Trang-Chu",
                    expanded: false,
                }, // QLTT

                {
                    id: "QLTT",
                    text: "Quản lý thông tin",
                    icon: "mdi mdi-inbox-arrow-down",
                    expanded: false,
                },
                {
                    id: "Quan-Ly-Don-Vi-Hoat-Dong",
                    text: "Đơn vị hoạt động",
                    icon: "mdi mdi-file-table-box",
                    expanded: false,
                    external: true,
                    parent: "QLTT",
                    to: FE_QLTT + "Quan-Ly-Don-Vi-Hoat-Dong",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.CUCDUONGBO"),
                        this.$i18n.t("Quyen.DanhSachDonViHoatDong")
                    ),
                },
                {
                    id: "Quan-Ly-Don-Vi-Truyen-Nhan",
                    text: "Đơn vị truyền nhận",
                    icon: "mdi mdi-file-table-box",
                    expanded: false,
                    external: true,
                    parent: "QLTT",
                    to: FE_QLTT + "Quan-Ly-Don-Vi-Truyen-Nhan",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.CUCDUONGBO"),
                        this.$i18n.t("Quyen.DanhSachDonViTruyenNhan")
                    ),
                },
                {
                    id: "Quan-Ly-Chu-Ky",
                    text: "Quản lý chữ ký",
                    icon: "mdi mdi-file-table-box",
                    expanded: false,
                    external: true,
                    parent: "QLTT",
                    to: FE_QLTT + "Quan-Ly-Chu-Ky",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.CUCDUONGBO"),
                        this.$i18n.t("Quyen.DanhSachChuKy")
                    ),
                },

                // Báo cáo QLBX
                {
                    id: "BCQLBX",
                    text: "Báo cáo Bến xe",
                    icon: "mdi mdi-chart-line-variant",
                    to: "",
                    expanded: false,
                },
                {
                    id: "BCHDX",
                    text: "Hoạt động của xe",
                    icon: "mdi mdi-inbox-arrow-down",
                    parent: "BCQLBX",
                    to: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Xe",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoBenXe"),
                        this.$i18n.t("Quyen.DanhSachBaoCaoHoatDongCuaXe")
                    ),
                },
                {
                    id: "BCHDXTT",
                    text: "Hoạt động của xe theo tháng",
                    icon: "mdi mdi-inbox-arrow-down",
                    parent: "BCQLBX",
                    to: "/Bao-Cao-Thong-Ke/Tong-Hop-Cua-Xe-Theo-Thang",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoBenXe"),
                        this.$i18n.t(
                            "Quyen.DanhSachBaoCaoHoatDongCuaXeTheoThang"
                        )
                    ),
                },
                {
                    id: "DSBDBCHDBX",
                    text: "Hoạt động của bến xe",
                    icon: "mdi mdi-inbox-arrow-down",
                    parent: "BCQLBX",
                    to: "/Bao-Cao-Thong-Ke/Bao-Cao-Hoat-Dong-Cua-Ben-Xe",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoBenXe"),
                        this.$i18n.t("Quyen.DanhSachBaoCaoHoatDongCuaBenXe")
                    ),
                },
                {
                    id: "TKBXCPMM",
                    text: "Trạng thái truyền tải dữ liệu của bến xe",
                    icon: "mdi mdi-inbox-arrow-down",
                    parent: "BCQLBX",
                    to: "/Bao-Cao-Thong-Ke/Trang-Thai-Truyen-Tai-Du-Lieu-Cua-Ben-Xe",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoBenXe"),
                        this.$i18n.t(
                            "Quyen.DanhSachBaoCaoTrangThaiTruyenTaiDuLieuCuaBenXe"
                        )
                    ),
                },
                {
                    id: "NKTT",
                    text: "Nhật ký truyền tải",
                    icon: "mdi mdi-inbox-arrow-down",
                    parent: "BCQLBX",
                    to: "/Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoBenXe"),
                        this.$i18n.t("Quyen.DanhSachBaoCaoNhatKyTruyenTai")
                    ),
                },
                {
                    id: "BCPTTDL",
                    text: "Báo cáo lỗi dữ liệu truyền tải",
                    icon: "mdi mdi-inbox-arrow-down",
                    parent: "BCQLBX",
                    to: "/Bao-Cao-Thong-Ke/Bao-Cao-Vi-Pham-Truyen-Tai-Du-Lieu",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoBenXe"),
                        this.$i18n.t("Quyen.DanhSachBaoCaoLoiDuLieuTruyenTai")
                    ),
                },

                // Báo cáo Lệnh
                {
                    id: "BaoCao",
                    text: "Báo cáo Lệnh vận chuyển",
                    icon: "mdi mdi-chart-line-variant",
                    expanded: false,
                },
                {
                    id: "BaoCao_TruyenTaiDuLieu",
                    text: "Nhật ký tiếp nhận dữ liệu",
                    icon: "mdi mdi-cloud-upload-outline",
                    external: true,
                    parent: "BaoCao",
                    to: FE_BaoCaoLenh + "bao-cao-thong-ke/truyen-tai-du-lieu",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoLenh"),
                        this.$i18n.t("Quyen.BaoCaoTruyenTaiDuLieuLenh")
                    ),
                },
                {
                    id: "BaoCao_TruyenTaiDuLieu_DoanhNghiep",
                    text: "Doanh nghiệp",
                    icon: "mdi mdi-home-city-outline",
                    expanded: false,
                    parent: "BaoCao",
                },
                {
                    id: "BaoCao_TruyenTaiDuLieu_DNTruyenTai",
                    text: "Truyền tải",
                    icon: "mdi mdi-connection",
                    external: true,
                    parent: "BaoCao_TruyenTaiDuLieu_DoanhNghiep",
                    to:
                        FE_BaoCaoLenh +
                        "bao-cao-thong-ke/truyen-tai-cua-doanh-nghiep",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoLenh"),
                        this.$i18n.t("Quyen.BaoCaoTruyenTaiLenhDoanhNghiep")
                    ),
                },
                {
                    id: "BaoCao_TruyenTaiDuLieuCuaDoanhNghiep",
                    text: "Tổng hợp tháng",
                    icon: "mdi mdi-chart-areaspline",
                    external: true,
                    parent: "BaoCao_TruyenTaiDuLieu_DoanhNghiep",
                    to:
                        FE_BaoCaoLenh +
                        "bao-cao-thong-ke/truyen-tai-du-lieu-cua-doanh-nghiep-trong-thang",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoLenh"),
                        this.$i18n.t(
                            "Quyen.BaoCaoTruyenTaiLenhDoanhNghiepTrongThang"
                        )
                    ),
                },
                {
                    id: "BaoCao_TruyenTaiDuLieu_BenXe",
                    text: "Bến xe",
                    icon: "mdi mdi-bus-stop",
                    expanded: false,
                    parent: "BaoCao",
                },
                {
                    id: "BaoCao_TruyenTaiDuLieu_BXTruyenTai",
                    text: "Truyền tải",
                    icon: "mdi mdi-connection",
                    external: true,
                    parent: "BaoCao_TruyenTaiDuLieu_BenXe",
                    to:
                        FE_BaoCaoLenh +
                        "bao-cao-thong-ke/truyen-tai-cua-ben-xe",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoLenh"),
                        this.$i18n.t("Quyen.BaoCaoTruyenTaiLenhBenXe")
                    ),
                },
                {
                    id: "BaoCao_TruyenTaiDuLieuCuaBenXe",
                    text: "Tổng hợp tháng",
                    icon: "mdi mdi-chart-areaspline",
                    external: true,
                    parent: "BaoCao_TruyenTaiDuLieu_BenXe",
                    to:
                        FE_BaoCaoLenh +
                        "bao-cao-thong-ke/truyen-tai-du-lieu-cua-ben-xe-trong-thang",
                    visible: this.$Helper.Common.CheckRole(
                        this.$i18n.t("Quyen.BaoCaoLenh"),
                        this.$i18n.t("Quyen.BaoCaoTruyenTaiLenhBenXeTrongThang")
                    ),
                },
                // {
                //     id: "BaoCao_HoatDongCuaXe",
                //     text: "Hoạt động của xe",
                //     icon: "mdi mdi-inbox-arrow-down",
                //     expanded: false,
                //     parent: "BaoCao",
                //     to: "/bao-cao-thong-ke/hoat-dong-cua-xe",
                // },
            ],
            openState: false,
            modelToast: {
                isVisible: false,
                message: "",
                type: "success",
            },
        };
    },
    methods: {
        DangXuat() {
            this.$Core.Auth.Logout();
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("onToast", (params) => {
            self.modelToast = params;
        });
    },
};
</script>

<style scoped>
>>> .icon-toast .dx-icon {
    font-size: 36px;
}
>>> .icon-toast {
    position: relative;
    left: -16px;
}
</style>